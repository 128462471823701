import React, { useContext } from 'react';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { notification } from 'antd';

import { AppContext } from '../AppContext';
import { generatePaymentItems } from '../utils';
import { API } from '../services/API';
import ShoppingCart from './ShoppingCart';
import RiderCard from './Components/RiderCard';
import PaymentCard from './Components/PaymentCard';
import AddressCard from './Components/AddressCard';

const addressValidator = (address) => {
    if (!address.email) throw new Error('Customer email required');
    if (!address.phone) throw new Error('Customer phone number required');
    if (!address.line1 || !address.zipCode || !address.city || !address.state) {
        throw new Error('Customer address required');
    }
};

export default function CheckoutPanel() {
    const {
        appState: { firstName, lastName, billing, account, newsletterSubscribed, total },
        appDispatch,
        productState,
    } = useContext(AppContext);

    const { showShipping, billingSameAsShipping } = productState;
    const { products } = productState.cartContent;
    // const { couponCode } = billing;
    // console.log(couponCode);
    const stripe = useStripe();
    const elements = useElements();

    const paymentSuccess = () => {
        console.log(`payment success`);
        setLoading(false);

        if (window.innerWidth > 426) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        notification.success({ message: 'Payment successful.' });
        appDispatch({ TYPE: 'PAYMENT_SUCCESS', payload: { paymentStatus: true } });

        // Facebook
        window.fbq('track', 'Purchase', {
            currency: 'USD',
            value: total,
        });
    };

    const setLoading = (loading) => {
        appDispatch({
            TYPE: 'UPDATE_LOADING',
            payload: {
                loading,
            },
        });
    };

    const paymentFail = (error) => {
        setLoading(false);
        const message = error?.response?.data?.message || error?.message || 'Something went wrong';
        appDispatch({
            TYPE: 'ERROR_MESSAGE',
            payload: { message },
        });
    };

    const getCustomerParams = () => {
        if (!firstName || !lastName) throw new Error('Customer name required');

        const name = `${firstName} ${lastName}`;

        if (!showShipping) {
            // in Rider Card
            const { email, zipCode } = billing;
            if (!email) throw new Error('Customer email required');

            if (!zipCode) throw new Error('Customer zip code required');
            return {
                name,
                email,
            };
        }

        // Address Card
        addressValidator(account);

        let accountAddress = { ...account, postal_code: account.zipCode };
        let billingAddress = { ...billing, postal_code: billing.zipCode };

        delete accountAddress.zipCode;
        delete accountAddress.email;
        delete accountAddress.phone;
        delete billingAddress.zipCode;
        delete billingAddress.email;
        delete billingAddress.phone;

        if (billingSameAsShipping) {
            billingAddress = accountAddress;
        } else {
            addressValidator(billing);
        }

        return {
            name,
            email: account.email,
            address: billingAddress,
            shipping: { address: accountAddress, phone: account.phone, name },
        };
    };

    const handleCheckout = async () => {
        setLoading(true);

        try {
            if (!stripe) {
                throw new Error('Stripe Service Not Available');
            }

            const customerParams = getCustomerParams();

            const customerRes = await API.post(`/stripe/customer`, customerParams);

            if (!customerRes.data || !customerRes.data.customerId) {
                throw new Error('Failed to create customer');
            }

            const { customerId } = customerRes.data;
            console.log(`customer id: ${customerId}`);

            const metadata = {
                title: products[0].title,
                amount: (total * 100).toFixed(0),
                newsletterSubscribed,
                site_url: window.location.origin,
                path: window.location.pathname.substring(1),
            };

            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: customerParams.name,
                    email: customerParams.email,
                    address: customerParams.address,
                },
                metadata,
            });

            console.log(`created payment method`);

            if (error || !paymentMethod || !paymentMethod.id) {
                throw new Error(error.message || 'Failed to create payment method');
            }

            console.log(`generating payment items`);

            const paymentItems = generatePaymentItems(products);
            console.log(`checkout request...`);
            const { couponCode } = billing;
            console.log(couponCode);
            const checkoutRes = await API.post(`/stripe/checkout`, {
                customerId,
                paymentId: paymentMethod.id,
                paymentItems,
                metadata,
                couponCode,
            });

            console.log(`checkout response: ${checkoutRes.status}`);

            if (checkoutRes?.status === 200) {
                // onSuccess();
                paymentSuccess();
                console.log(`payment successful`);
            } else {
                throw new Error(checkoutRes?.message);
            }
        } catch (error) {
            // onFail(error);;
            paymentFail(error);
        }
    };

    return (
        <div className={'transit'}>
            <ShoppingCart />
            <PaymentCard />
            {showShipping ? <AddressCard type={'account'} /> : <RiderCard />}

            <div className="c-card__btn-wrapper">
                <button className="c-card__btn web-view" onClick={handleCheckout}>
                    Submit
                </button>
            </div>
        </div>
    );
}
