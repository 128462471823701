import React from 'react';

import { VisaIcon } from '../../assets/Icons/CustomIcons';

export default function CreditCard({ firstName, lastName }) {
    return (
        <div className="col-lg-6 col-xs-12">
            <div className="c-card__card-represent">
                <div className="row">
                    <div className="col-12">
                        <VisaIcon />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 c-card__card-represent-number">**** **** **** ****</div>
                </div>

                <div className="row c-card__card-represent-key">
                    <div className="col-7">Card Holder</div>
                    <div className="col-3">Exp</div>
                    <div className="col-1">CVC</div>
                </div>
                <div className="row c-card__card-represent-value">
                    <div className="col-7">{firstName + ' ' + lastName}</div>
                    <div className="col-3">**/**</div>
                    <div className="col-1">***</div>
                </div>
            </div>
        </div>
    );
}
