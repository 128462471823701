import React from 'react';

import ProductDescription from './ProductDescription';
import ProductQuantity from './ProductQuantity';

const ShoppingCartItem = ({ index, item }) => {
    if (!item.title) {
        return <> </>;
    }

    return (
        <div className="row c-card__item">
            <div className="col-2">
                <img
                    src={item.image}
                    alt={item.title}
                    height={50}
                    width={50}
                    style={{ objectFit: 'contain' }}
                />
            </div>

            <ProductDescription title={item.title} body={item.body} />

            {item.cost === 0 ? '' : <ProductQuantity index={index} />}

            <div className="col-2 text-right">
                {item.cost === 0 ? '' : `$ ${item.cost.toFixed(2)}`}
            </div>
        </div>
    );
};

export default ShoppingCartItem;
