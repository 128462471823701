import React, { useContext } from 'react';

import { AppContext } from '../AppContext';
import CONSTANTS from '../constants/constant';
import AppStore from '../assets/img/AppStore.png';
import GooglePlay from '../assets/img/GooglePlay.png';

export default function SuccessShare() {
    const {
        appState: { account, firstName, lastName },
        productState: { title, cartContent },
    } = useContext(AppContext);

    const [product] = cartContent.products;

    let iPhoneAppLink;

    if (title.includes('SV1')) {
        iPhoneAppLink = CONSTANTS.iPhoneAppLink_NEW;
    } else if (title.includes('Classon')) {
        iPhoneAppLink = CONSTANTS.iPhoneAppLink_LEGACY;
    }

    if (window.growsurf) {
        window.growsurf.init();

        if (process.env.NODE_ENV !== 'development') {
            window.growsurf.triggerReferral({
                email: account.email,
                firstName: firstName,
                lastName: lastName,
            });
        }
    }

    const style = {
        instructions: {
            color: 'rgba(0,0,0,0.5)',
            'font-weight': '500',
        },
        copyButton: {
            background:
                'linear-gradient(51.93deg, #E64CCD 12.2%, #DE4DCB 32.14%, #EF5395 60.3%, #FA4A6F 87.29%)',
        },
    };

    return (
        <div id="right-side-success" className=" transit1 c-checkout__right-side-success">
            <div className="share-top-container">
                <img src={product.image} alt={product.title} className="share-product-image" />
                <div className="share-subtitle">You have successfully subscribed to {title}.</div>
                <div className="share-title">What&apos;s Next?</div>
            </div>
            <div className="share-highlight-container">
                <div className="share-highlight">1. Download our App.</div>
                <p className="share-body">
                    Re-built from the ground up, our mobile app is your control to everything
                    Beyond.
                </p>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <a href={iPhoneAppLink} target="_blank" rel="noopener noreferrer">
                        <img src={AppStore} style={{ marginRight: '8px', marginBottom: '8px' }} />
                    </a>
                    <a href={CONSTANTS.AndroidAppLink} target="_blank" rel="noopener noreferrer">
                        <img src={GooglePlay} />
                    </a>
                </div>
            </div>

            <div className="share-highlight-container">
                <div className="share-highlight">2. Share Beyond. Get $20.</div>
                <p className="share-body">
                    For each friend you refer to Beyond who subscribes, we’ll give you $20.
                </p>

                <div
                    data-grsf-block-form
                    data-grsf-email={account.email}
                    data-grsf-first-name={firstName}
                    data-grsf-last-name={lastName}
                    data-grsf-share-instructions="Invite your friends by using your unique link:"
                    data-grsf-share-instructions-style={JSON.stringify(style.instructions)}
                    data-grsf-button-style={JSON.stringify(style.copyButton)}
                ></div>
            </div>
        </div>
    );
}
