import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import AppRoute from './AppRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import './styles/app.scss';

ReactDOM.render(
    <React.Fragment>
        <AppRoute />
    </React.Fragment>,
    document.getElementById('root'),
);
reportWebVitals();
