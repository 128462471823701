import React from 'react';
import { CheckIcon } from '../../assets/Icons/CustomIcons';

export default function ProductDescription({ description, highlights }) {
    return (
        <>
            <div className="c-infopage__description-title">Description</div>

            <div style={{ paddingBottom: '15px' }}>
                {description.map((desc, index) => {
                    return (
                        <p key={index} className="c-infopage__description">
                            {desc}
                        </p>
                    );
                })}
            </div>
            <div>
                {highlights.map((highlight, index) => (
                    <div className="row" key={index}>
                        <div className="">
                            <CheckIcon />
                        </div>
                        <div
                            className="pl-2 font-weight-bold"
                            style={{
                                alignSelf: 'flex-end',
                            }}
                        >
                            {highlight}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
