import { createContext } from 'react';
export const DefaultAppState = {
    appState: {
        firstName: '',
        lastName: '',
        billing: {
            email: '',
            phone: '',
            zipCode: '',
            line1: '',
            line2: '',
            city: '',
            state: '',
            metaData: {},
            promotionCode: '',
            couponCode: '',
        },
        account: {
            email: '',
            phone: '',
            zipCode: '',
            line1: '',
            line2: '',
            city: '',
            state: '',
            metaData: {},
        },
        errorMessage: null,
        paymentSuccess: false,
        newsletterSubscribed: true,
        loading: false,
        total: 0,
        couponDiscount: 0,
    },
    appDispatch: null,
    productState: {},
    productDispatch: null,
};

export const AppContext = createContext(null);
