import React from 'react';

import { CartItems } from './Items';
import { BV1Gallery } from './gallery';

const { BV1Reservation, BV1BeyondPremiereFree } = CartItems.Products;

export const BV1ResPageContent = {
    title: 'CMYK Folding E-Bike (black)',
    productContent: {
        subtitle: (
            <>
                <div className="c-infopage__subtitle font-weight-bold">
                    <a href="#right-side">
                        <span className="web-view">Reserve now for $1</span>
                        {/* <span className="mobile-view">Reserve now for $1</span> */}
                    </a>
                    {'  '}(arrives by the end of summer 2022)
                </div>
                <p>includes Beyond Premiere free for 6 months</p>
            </>
        ),
        gallery: BV1Gallery,
        description: [
            'Our CMYK foldable electric bike is the most affordable way to own an electric bike. The CMYK has a range of 20-45 miles (depending on riding mode), comes with a swappable battery, quick one-step folding system, weighs only 37 lbs. and has a 250-350 watt front motor. The CMYK also comes equipped with a built in USB port to charge mobile devices on the go, has an integrated GPS tracker and is safe to ride in the rain. Current color option is black. Cancel anytime. $99 one-time setup fee.',
        ],
        highlights: [
            '24/7 support',
            'GPS Tracking',
            'VIP service & maintenance',
            'Exclusive member events and rides',
            'Member-only discounts from top brands (coming soon)',
        ],
        footerHighlights: [
            '24/7 Support',
            'Local Maintenance',
            'Free e-bike repair or swaps',
            'GPS Tracking',
            'Exclusive member events and rides',
            'Member-only discounts from top brands (coming soon)',
        ],
    },
    cartContent: {
        products: [BV1Reservation, BV1BeyondPremiereFree],
        footerContent: [
            '* Monthly subscription of $89/mo. will begin at time of pickup',
            '** Pickup available at Beyond HQ (368 Broadway, New York, NY 10013)',
        ],
    },
};
