import React, { useState, useContext, useEffect } from 'react';
import { Image } from 'antd';

import { AppContext } from '../AppContext';
import ProductDescription from './Components/ProductDescription';
import ProductFooter from './Components/ProductFooter';

export default function ProductInfo() {
    const { productState: state } = useContext(AppContext);
    const { productContent } = state;

    return (
        <div className="c-checkout__left-side col-md-6 col-xs-12 ">
            <div className="c-infopage-wrapper">
                <h2 className="c-infopage__title">{state.title}</h2>
                {/* {productContent.subtitle} */}

                <ImageGallery gallery={productContent.gallery} />

                <ColorPicker />

                <ProductDescription
                    description={productContent.description}
                    highlights={productContent.highlights}
                />

                <ProductFooter />
            </div>
        </div>
    );
}

const ImageGallery = ({ gallery }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        setSelectedIndex(0);
    }, [gallery]);

    return (
        <div style={{ marginTop: '12px' }}>
            <div className="c-infopage__image-wrapper">
                <Image
                    src={gallery[selectedIndex] && gallery[selectedIndex].product}
                    style={{ objectFit: 'contain', marginBottom: '12px' }}
                />
            </div>
            <div className="c-infopage__image-selection-wrapper">
                {gallery.map((item, index) => {
                    const opacity = index === selectedIndex ? 1 : 0.6;
                    return (
                        <div
                            key={index}
                            className={`c-infopage__image-selection-img ${
                                index === selectedIndex && 'c-infopage__image-selection-img--active'
                            }`}
                            onClick={() => setSelectedIndex(index)}
                        >
                            <img src={item.thumbnail} style={{ objectFit: 'contain', opacity }} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const ColorPicker = () => {
    const colors = ['#00C1E7', '#000000', '#FFFFFF'];
    const colorNames = ['Blue', 'Black', 'White'];
    const { productState: state, productDispatch: dispatch } = useContext(AppContext);

    return (
        <>
            {state.colors && (
                <div style={{ marginTop: '24px', marginBottom: '24px' }}>
                    <div className="font-weight-bold">Choose Your Color:</div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        {colors.map((color, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        display: 'flex',
                                        borderRadius: '25px',
                                        borderWidth: '3px',
                                        borderColor: 'black',
                                        borderStyle:
                                            state.selectedColor === colorNames[index]
                                                ? 'solid'
                                                : 'hidden',
                                        marginRight: '15px',
                                    }}
                                >
                                    <button
                                        style={{
                                            width: '35px',
                                            height: '35px',
                                            margin: 'auto',
                                            borderRadius: '20px',
                                            borderWidth: color === '#FFFFFF' ? '1px' : '0',
                                            borderColor:
                                                color === '#FFFFFF' ? 'rgba(0,0,0,0.6)' : color,
                                            background: color,
                                        }}
                                        onClick={() => {
                                            dispatch({
                                                TYPE: 'COLOR_UPDATE',
                                                payload: { selectedColor: colorNames[index] },
                                            });
                                        }}
                                    ></button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};
