import React, { useEffect, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Spin, notification } from 'antd';
import { Elements } from '@stripe/react-stripe-js';
import { AppContext } from './AppContext';
import Navbar from './Components/Navbar';
import ProductInfo from './ProductInfo';
import CheckoutPanel from './CheckoutPanel';
import SuccessPayment from './Components/successPayment';
import SuccessShare from './Components/SuccessShare';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLEKEY);
// const stripe = require('stripe')('sk_test_ZvFJD3CQrGzYTRIr5VxkFrI4');
// console.log(stripe);
// (async () => {
//     const promotionCodes = await stripe.customers.list();
//     console.log(promotionCodes);
// })();
const App = () => {
    const {
        appState,
        appDispatch,
        productState: { allowRefer, title },
    } = useContext(AppContext);
    const { paymentSuccess, loading } = appState;
    const successPage = allowRefer ? <SuccessShare /> : <SuccessPayment />;

    useEffect(() => {
        document.title = `Beyond - ${title}`;
    }, [title]);

    useEffect(() => {
        if (allowRefer) {
            const script = document.createElement('script');
            script.src = 'https://app.growsurf.com/growsurf.js?v=2.0.0';
            script.setAttribute('grsf-campaign', 'ceiz5s');
            script.async = true;
            document.head.appendChild(script);
        }
    }, [allowRefer]);

    useEffect(() => {
        if (!appState) return;

        if (appState.errorMessage) {
            notification.error({
                message: appState.errorMessage,
            });
            appDispatch({
                TYPE: 'ERROR_MESSAGE',
                payload: { message: null },
            });
        }
    }, [appState, appDispatch]);

    return (
        <Elements stripe={stripePromise}>
            <Spin
                spinning={loading}
                size={'large'}
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <Navbar />
                <div className="c-checkout row">
                    <ProductInfo />
                    <div className="c-checkout__right-side col-md-6 col-xs-12 " id="right-side">
                        <div className="web-view">
                            {paymentSuccess ? successPage : <CheckoutPanel />}
                        </div>
                    </div>
                </div>
            </Spin>
        </Elements>
    );
};

export default App;
