import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../AppContext';
import CONSTANTS from '../../../constants/constant';
import { formInputFormatter } from '../../../utils';
import { API } from '../../../services/API';
//import axios from 'axios';
const calculateSubtotal = (products) => {
    return products
        .reduce((sum, cur) => Number(sum) + Number(cur.cost) * Number(cur.quantity), 0)
        .toFixed(2);
};
const CartFooter = () => {
    const {
        appDispatch,
        appState,
        couponValue,
        productState: { cartContent },
    } = useContext(AppContext);

    const subtotal = calculateSubtotal(cartContent.products);
    const tax = (subtotal * CONSTANTS.TAX_NY).toFixed(2);
    const total =
        (Number(subtotal) + Number(tax)).toFixed(2) - Number(appState.couponDiscount.toFixed(2));
    const handleKeyDown = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };
    useEffect(() => {
        appDispatch({ TYPE: 'UPDATE_TOTAL', payload: { total } });
    }, [total, appDispatch]);

    const UpdatepromotionCode = async (e) => {
        const [field, value] = formInputFormatter(e.target.name, e.target.value);
        appDispatch({
            TYPE: 'UPDATE_COUPON',
            payload: { field, value },
        });
    };
    const ApplyCoupon = async () => {
        // e.preventDefault();
        var { couponDiscount } = appState;
        var { promotionCode } = appState.billing;
        var { couponCode } = appState.billing;
        //ask for coupon info with  promotion code in stripe api service
        const couponDiscountValue = await API.post(`/stripe/get-coupon`, {
            promotionCode: promotionCode,
        });
        // console.log(couponDiscountValue.data[0]);
        let percent_off = couponDiscountValue.data[0].coupon.percent_off;
        let amount_off = couponDiscountValue.data[0].coupon.amount_off;

        //  console.log(percent_off);
        //  console.log(amount_off);
        // validating response from lambda
        if (couponDiscountValue.data[0]) {
            if (percent_off) {
                couponDiscount = (percent_off / 100) * subtotal;
                // console.log(couponDiscount);
                couponCode = couponDiscountValue.data[0].coupon.id;
                // console.log(couponDiscountValue.data[0].coupon.amount_off);
                // console.log(couponDiscountValue.data[0].coupon.id);
            }
            if (amount_off) {
                couponDiscount = couponDiscountValue.data[0].coupon.amount_off / 100;
                couponCode = couponDiscountValue.data[0].coupon.id;
                // console.log(couponDiscountValue.data[0].coupon.amount_off);
                // console.log(couponDiscountValue.data[0].coupon.id);
            }
        } else {
            couponDiscount = 0;
            couponCode = '';
        }
        // // calculate total with coupon
        const total =
            (Number(subtotal) + Number(tax)).toFixed(2) - Number(couponDiscount).toFixed(2);
        //dispatch
        if (couponDiscount !== 0) {
            // console.log(couponDiscountValue.data[0]);
            appDispatch({ TYPE: 'UPDATE_TOTAL', payload: { total } });
            appDispatch({ TYPE: 'COUPON_DISCOUNT', payload: { couponDiscount } });
            appDispatch({ TYPE: 'UPDATE_COUPON_CODE', payload: { couponCode } });
        } else {
            couponDiscount = 0;
            appDispatch({ TYPE: 'UPDATE_TOTAL', payload: { total } });
            appDispatch({ TYPE: 'COUPON_DISCOUNT', payload: { couponDiscount } });
            appDispatch({ TYPE: 'UPDATE_COUPON_CODE', payload: { couponCode } });
        }
        // var { couponCode } = appState.billing;
        // console.log(couponCode);
    };
    return (
        <div className="row c-card__footer">
            <div className="col-8">
                <div className="row">
                    <div className="col-8">
                        <input
                            className="c-shoppingCart__input-coupon"
                            placeholder=" Coupon code"
                            name="promotionCode"
                            value={couponValue}
                            onKeyDown={handleKeyDown}
                            onChange={UpdatepromotionCode}
                        />
                    </div>
                    <div className="col-4">
                        <button
                            className="c-shoppingCart__btn-coupon"
                            name="promotionCode"
                            onClick={ApplyCoupon}
                            block
                        >
                            Apply Coupon
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-1 c-card__footer-subtotal">Subtotal</div>
            <div className="col-3  c-card__footer-subtotal-value">$ {subtotal}</div>
            <div className="col-6"></div>
            {appState.couponDiscount === 0 ? null : (
                <React.Fragment>
                    <div className="col-3 c-card__footer-subtotal">Discount</div>
                    <div className="col-3  c-card__footer-subtotal-value">
                        - $ {appState.couponDiscount.toFixed(2)}
                    </div>
                    <div className="col-6"></div>
                </React.Fragment>
            )}
            <div className="col-3 c-card__footer-subtotal">Tax</div>
            <div className="col-3  c-card__footer-subtotal-value">$ {tax}</div>
            <div className="col-6"></div>
            <div className="col-3 c-card__footer-total">Total</div>
            <div className="col-3  c-card__footer-total-value">$ {total.toFixed(2)}</div>
            <div className="c-card__footer-terms col-12">
                {cartContent.footerContent.map((footerItem, index) => (
                    <em key={index}>
                        {footerItem}
                        <br />
                    </em>
                ))}
            </div>
        </div>
    );
};

export default CartFooter;
