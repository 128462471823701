import React, { useReducer } from 'react';
import { Route } from 'react-router-dom';

import App from './App';
import { appStateReducer } from './Reducer';
import { AppContext, DefaultAppState } from './AppContext';

const ContextRoute = ({ productReducer, productContent, ...rest }) => {
    const [appState, appDispatch] = useReducer(appStateReducer, DefaultAppState);
    const [productState, productDispatch] = useReducer(productReducer, productContent);

    const state = { ...appState, appDispatch, productState, productDispatch };
    return (
        <Route {...rest}>
            <AppContext.Provider value={state}>
                <App />
            </AppContext.Provider>
        </Route>
    );
};

export default ContextRoute;
