import React from 'react';

import { CartItems } from './Items';
import { SV1Gallery } from './gallery';

const { Products, Footer } = CartItems;

const ProductContent = {
    title: 'SV1 Electric Scooter',
    subtitle: (
        <>
            <div className="c-infopage__subtitle font-weight-bold">
                <a href="#right-side">
                    <span className="web-view">Reserve now for $1</span>
                    {/* <span className="mobile-view">Reserve now for $1</span> */}
                </a>
                {'  '}(arrives in early April)
            </div>
            <p>$49/mo. plan + Beyond Premiere free for 6 months</p>
        </>
    ),
    gallery: SV1Gallery,
    description: [
        'The SV1 Electric Scooter integrates seamlessly with public transportation and comes Quorra-ready. With 15 miles of range per battery, 20 mph top speed, airless tires, and weighing less than 26 lbs – it easily folds so you can travel wherever the road takes you. There is a one time set-up fee of $50 for new riders.',
    ],
    highlights: [
        'month-to-month; cancel anytime',
        'VIP same-day service & maintenance',
        'arrives in early April',
    ],
    footerHighlights: null,
};

const reservationCartContent = {
    products: [Products.SV1Reservation, Products.BeyondPremiereFree],
    footerContent: Footer.SV1PickUpFooter,
};

const noSetupFeeCartContent = {
    products: [Products.SV1NoSetupFee, Products.SpringPromo, Products.BeyondPremiereFree],
    footerContent: Footer.SV1PickUpFooter,
};

const marchPromoCartContent = {
    products: [Products.SV1Reservation, Products.MarchPromo, Products.BeyondPremiereFree],
    footerContent: Footer.MarchPromoFooter,
};

const baseContent = { title: 'SV1 Electric Scooter', productContent: ProductContent };

export const SV1ReservationPageContent = {
    ...baseContent,
    cartContent: reservationCartContent,
};
export const SV1NoSetupFeePageContent = {
    ...baseContent,
    cartContent: noSetupFeeCartContent,
};
export const SV1MarchPromoPageContent = {
    ...baseContent,
    cartContent: marchPromoCartContent,
};
