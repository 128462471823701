import React, { useContext } from 'react';

import { AppContext } from '../../../AppContext';

const ProductQuantity = ({ index }) => {
    const {
        productState: {
            cartContent: { products },
        },
        //appState,
        productDispatch: dispatch,
    } = useContext(AppContext);

    const product = products[index];
    return (
        <div className="col-3">
            <div className={`c-input-num row ${product.disabled && ' c-input-num--disabled'}`}>
                <span
                    className={`col-4 `}
                    onClick={() =>
                        dispatch({
                            TYPE: 'QUANTITY_UPDATE',
                            payload: { index: index, operator: '-' },
                        })
                    }
                >
                    -
                </span>
                <span
                    className="col-4"
                    style={{
                        alignSelf: 'center',
                    }}
                >
                    {product.quantity}
                </span>
                <span
                    className={`col-4 `}
                    onClick={() =>
                        dispatch({
                            TYPE: 'QUANTITY_UPDATE',
                            payload: { index: index, operator: '+' },
                        })
                    }
                >
                    +
                </span>
            </div>
        </div>
    );
};

export default ProductQuantity;
