// SV1 Asset
import SV1Thumbnail1 from '../assets/img/Product/SV1Thumbnail1.png';
import SV1Thumbnail2 from '../assets/img/Product/SV1Thumbnail2.png';
import SV1Thumbnail3 from '../assets/img/Product/SV1Thumbnail3.png';
import SV1 from '../assets/img/image1.png';
import productPicture2 from '../assets/img/image2.png';
import Lock from '../assets/img/img5.jpg';
import SummerSale from '../assets/img/Product/SummerSale.png';

// Classon Asset
import ClassonThumbnailBlue1 from '../assets/img/Product/ClassonThumbnailBlue1.png';
import ClassonThumbnailBlue2 from '../assets/img/Product/ClassonThumbnailBlue2.png';
import ClassonThumbnailBlue3 from '../assets/img/Product/ClassonThumbnailBlue3.png';
import ClassonThumbnailBlue4 from '../assets/img/Product/ClassonThumbnailBlue4.png';
import ClassonBlue1 from '../assets/img/Product/ClassonBlue1.png';
import ClassonBlue2 from '../assets/img/Product/ClassonBlue2.png';
import ClassonBlue3 from '../assets/img/Product/ClassonBlue3.png';
import ClassonBlue4 from '../assets/img/Product/ClassonBlue4.png';
import ClassonIconBlue from '../assets/img/Product/ClassonIconBlue.png';

import ClassonThumbnailBlack1 from '../assets/img/Product/ClassonThumbnailBlack1.png';
import ClassonThumbnailBlack2 from '../assets/img/Product/ClassonThumbnailBlack2.png';
import ClassonThumbnailBlack3 from '../assets/img/Product/ClassonThumbnailBlack3.png';
import ClassonBlack1 from '../assets/img/Product/ClassonBlack1.png';
import ClassonBlack2 from '../assets/img/Product/ClassonBlack2.png';
import ClassonBlack3 from '../assets/img/Product/ClassonBlack3.png';
import ClassonIconBlack from '../assets/img/Product/ClassonIconBlack.png';

import ClassonThumbnailWhite1 from '../assets/img/Product/ClassonThumbnailWhite1.png';
import ClassonThumbnailWhite2 from '../assets/img/Product/ClassonThumbnailWhite2.png';
import ClassonWhite1 from '../assets/img/Product/ClassonWhite1.png';
import ClassonWhite2 from '../assets/img/Product/ClassonWhite2.png';
import ClassonIconWhite from '../assets/img/Product/ClassonIconWhite.png';

// EBike Asset
import EBikeThumbnailBlack1 from '../assets/img/Product/EBikeThumbnailBlack1.png';
import EBikeBlack1 from '../assets/img/Product/EBikeBlack1.png';

// SV1
export const SV1Gallery = [
    { thumbnail: SV1Thumbnail1, product: SV1 },
    { thumbnail: SV1Thumbnail2, product: productPicture2 },
    { thumbnail: SV1Thumbnail3, product: Lock },
];

export const SV1SummerSaleGallery = [
    { thumbnail: SV1Thumbnail1, product: SummerSale },
    { thumbnail: SV1Thumbnail2, product: productPicture2 },
    { thumbnail: SV1Thumbnail3, product: Lock },
];

// Classon
export const ClassonGallery = {
    Blue: [
        { thumbnail: ClassonThumbnailBlue1, product: ClassonBlue1 },
        { thumbnail: ClassonThumbnailBlue2, product: ClassonBlue2 },
        { thumbnail: ClassonThumbnailBlue3, product: ClassonBlue3 },
        { thumbnail: ClassonThumbnailBlue4, product: ClassonBlue4 },
    ],
    Black: [
        { thumbnail: ClassonThumbnailBlack1, product: ClassonBlack1 },
        { thumbnail: ClassonThumbnailBlack2, product: ClassonBlack2 },
        { thumbnail: ClassonThumbnailBlack3, product: ClassonBlack3 },
        { thumbnail: ClassonThumbnailBlue4, product: ClassonBlue4 },
    ],
    White: [
        { thumbnail: ClassonThumbnailWhite1, product: ClassonWhite1 },
        { thumbnail: ClassonThumbnailWhite2, product: ClassonWhite2 },
        { thumbnail: ClassonThumbnailBlue4, product: ClassonBlue4 },
    ],
};

export const ClassonIcons = {
    Blue: ClassonIconBlue,
    Black: ClassonIconBlack,
    White: ClassonIconWhite,
};

// BV1
export const BV1Gallery = [{ thumbnail: EBikeThumbnailBlack1, product: EBikeBlack1 }];
