import React, { useContext } from 'react';

import { Card } from 'antd';

import { AppContext } from '../../AppContext';
import { PaymentProcessIcon } from '../../assets/Icons/CustomIcons';
import AddressCard from './AddressCard';
import PaymentForm from './PaymentForm';
import CreditCard from './CreditCard';

const PaymentCard = () => {
    const {
        appState: { firstName, lastName },
        productState,
        productDispatch,
    } = useContext(AppContext);

    const { showShipping, billingSameAsShipping } = productState;
    const toggleBillingCard = () => {
        productDispatch({
            TYPE: 'TOGGLE_SHOW_BILLING',
            payload: {},
        });
    };

    return (
        <div>
            <Card
                title={<div className="c-card__title">Payment</div>}
                bordered={false}
                extra={<PaymentProcessIcon />}
                className="c-card border-0"
            >
                {productState.paymentNote && (
                    <div className="col-10" style={{ marginBottom: '8px' }}>
                        {productState.paymentNote.map((note, index) => {
                            return (
                                <p
                                    className="font-weight-bold"
                                    key={index}
                                    style={{ marginBottom: '0px' }}
                                >
                                    {note}
                                </p>
                            );
                        })}
                    </div>
                )}

                <div className="row c-card-payment">
                    <PaymentForm />
                    <CreditCard firstName={firstName} lastName={lastName} />

                    {showShipping && (
                        <div className=" col-12 mt-3">
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    id="payment_checkbox"
                                    name="payment_checkbox"
                                    checked={billingSameAsShipping}
                                    onChange={toggleBillingCard}
                                />
                                <label htmlFor="payment_checkbox">
                                    <span>billing address is same as current address</span>
                                </label>
                            </div>
                        </div>
                    )}
                </div>
            </Card>

            {billingSameAsShipping === false && <AddressCard type={'billing'} />}
        </div>
    );
};
export default PaymentCard;
