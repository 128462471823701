import React from 'react';

import { CartItems } from './Items';
import { ClassonGallery } from './gallery';

const { ClassonBeta } = CartItems.Products;

export const ClassonBetaPageContent = {
    title: 'Classon Smart Helmet (Beta)',
    productContent: {
        subtitle: (
            <>
                <div className="c-infopage__subtitle font-weight-bold">
                    <a href="#right-side">
                        <strike style={{ color: 'black' }}>$399</strike>{' '}
                        <span className=" web-view">$199</span>
                        {/* <span className="mobile-view">$199</span> */}
                    </a>
                    {'  '}(Beta program pricing)
                </div>

                <p>includes full warranty and 24/7 support</p>
            </>
        ),
        gallery: ClassonGallery.Blue,
        description: [
            'Our Smart Helmet Beta program brings the latest products to our riders as we’re developing them. You will get the same experience and service as a brand new product – and the more you use it, give us feedback, and collaborate with us, the better we can improve it in real time. Current improvements are to the operating system.',
            'The Classon Smart Helmet is safety redesigned – a helmet like you’ve never seen before. With an intgrated control system, the Classon Smart Helmet has gesture activated turning signals, automatic brake & running lights and a front facing camera so you can record your ride, upload to the cloud, and access videos on your phone at the touch of a button. A priceless feature in case you get into an accident.',
        ],
        highlights: [
            '24/7 support',
            'Free helmet replacement in case of accident',
            'Full warranty',
            'VIP service & maintenance',
            'Exclusive member events and rides',
            'Member-only discounts from top brands (coming soon)',
            'Ready for pickup/shipping',
        ],
        footerHighlights: null,
    },
    cartContent: {
        products: [ClassonBeta],
        footerContent: [],
    },
    colors: ['Blue', 'Black', 'White'],
    selectedColor: 'Blue',
    showShipping: true,
    billingSameAsShipping: true,
};
