import React, { useContext } from 'react';
import { Card } from 'antd';

import { AppContext } from '../../AppContext';
import { formInputFormatter } from '../../utils';
import { BillingProcessIcon, PaymentProcessIcon } from '../../assets/Icons/CustomIcons';

export default function AddressCard({ type = 'billing' }) {
    const {
        appState: { firstName, lastName, billing, account, newsletterSubscribed },
        appDispatch,
    } = useContext(AppContext);

    let address, rightIcon, showNewsletter, actionType;

    if (type === 'billing') {
        address = billing;
        rightIcon = <PaymentProcessIcon />;
        showNewsletter = false;
        actionType = 'UPDATE_BILLING';
    } else if (type === 'account') {
        address = account;
        rightIcon = <BillingProcessIcon />;
        showNewsletter = true;
        actionType = 'UPDATE_ACCOUNT';
    }

    const updateAddress = (e) => {
        const [field, value] = formInputFormatter(e.target.name, e.target.value);

        appDispatch({
            TYPE: actionType,
            payload: { field, value },
        });
    };

    return (
        <Card
            title={
                <div className="c-card__title">
                    {type === 'billing' ? 'Billing Address' : 'Account Information'}
                </div>
            }
            bordered={false}
            extra={rightIcon}
            className="c-card border-0"
        >
            {type === 'account' && (
                <div className="col-10 font-weight-bold" style={{ marginBottom: '8px' }}>
                    <p style={{ marginBottom: '0px' }}>
                        * Your account name must match your ID and credit card information
                    </p>
                </div>
            )}

            <div className="pb-3">
                <div className="row c-card__input ">
                    <div className="col-6">
                        <input
                            className="c-input"
                            placeholder="First Name"
                            name="firstName"
                            value={firstName}
                            // onChange={updateAddress}
                            readOnly={true}
                        />
                    </div>
                    <div className="col-6">
                        <input
                            name="lastName"
                            className="c-input"
                            placeholder="Last Name"
                            value={lastName}
                            // onChange={updateAddress}
                            readOnly={true}
                        />
                    </div>

                    <div className="col-6">
                        <input
                            className="c-input c-input-margin"
                            placeholder="Phone"
                            name="phone"
                            type="number"
                            value={address.phone}
                            onChange={updateAddress}
                        />
                    </div>
                    <div className="col-6">
                        <input
                            name="email"
                            className="c-input c-input-margin"
                            placeholder="Email"
                            type="email"
                            value={address.email}
                            onChange={updateAddress}
                        />
                    </div>

                    <div className="col-12">
                        <input
                            name="line1"
                            className="c-input c-input-margin"
                            placeholder="Address - line 1"
                            value={address.line1}
                            onChange={updateAddress}
                        />
                    </div>

                    <div className="col-8">
                        <input
                            className="c-input c-input-margin"
                            placeholder="Address - line 2 (Apt, Suite, Unit)"
                            name="line2"
                            value={address.line2}
                            onChange={updateAddress}
                        />
                    </div>
                    <div className="col-4">
                        <input
                            name="zipCode"
                            type="number"
                            className="c-input c-input-margin"
                            placeholder="Zip Code"
                            value={address.zipCode}
                            onChange={updateAddress}
                        />
                    </div>

                    <div className="col-6">
                        <input
                            className="c-input c-input-margin"
                            placeholder="City"
                            name="city"
                            value={address.city}
                            onChange={updateAddress}
                        />
                    </div>
                    <div className="col-6">
                        <input
                            name="state"
                            className="c-input c-input-margin"
                            placeholder="State"
                            value={address.state}
                            onChange={updateAddress}
                        />
                    </div>

                    {showNewsletter && (
                        <div className=" col-12 mt-3">
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    id="checkbox"
                                    name="checkbox"
                                    checked={newsletterSubscribed}
                                    onChange={() =>
                                        appDispatch({
                                            TYPE: 'TOGGLE_NEWS_SUBSCRIPTION',
                                            payload: {},
                                        })
                                    }
                                />
                                <label htmlFor="checkbox">
                                    <span>subscribe to newsletter</span>
                                </label>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Card>
    );
}
