const handleQuantityIncrement = (products, index) => {
    let result = products;
    const product = products[index];
    const isBattery = product.title?.includes('Battery');

    if (isBattery) {
        const maxBattery = products[0].quantity * 2;
        if (product.quantity < maxBattery) {
            product.quantity += 1;
            result[index] = product;
        }
    } else {
        result = products.map((currentProduct) => {
            if (!currentProduct.title?.includes('Battery')) {
                currentProduct.quantity += 1;
            }
            return currentProduct;
        });
    }

    return result;
};

const handleQuantityDecrement = (products, index) => {
    let result = products;
    const product = products[index];
    const isBattery = product.title?.includes('Battery');
    const newQuantity = product.quantity - 1;

    if (isBattery) {
        if (newQuantity >= 0) {
            product.quantity -= 1;
            result[index] = product;
        }
    } else {
        if (newQuantity > 0) {
            const maxBattery = newQuantity * 2;
            result = products.map((product) => {
                if (!product.title?.includes('Battery')) {
                    product.quantity = newQuantity;
                } else {
                    if (product.quantity > maxBattery) {
                        product.quantity = maxBattery;
                    }
                }
                return product;
            });
        }
    }

    return result;
};

export const SV1SubReducer = (state, { TYPE, payload }) => {
    let products = state.cartContent.products;
    switch (TYPE) {
        case 'QUANTITY_UPDATE':
            const product = products[payload.index];

            if (product.disabled) return state;

            switch (payload.operator) {
                case '+':
                    products = handleQuantityIncrement(products, payload.index);
                    break;
                case '-':
                    products = handleQuantityDecrement(products, payload.index);
                    break;
                default:
                    return state;
            }

            return {
                ...state,
                cartContent: { ...state.cartContent, products },
            };
        case 'TOGGLE_SHOW_BILLING':
            return { ...state, billingSameAsShipping: !state.billingSameAsShipping };
        default:
            return state;
    }
};
