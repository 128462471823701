import { ClassonGallery, ClassonIcons } from '../PageContent/gallery';

export const ClassonReducer = (state, { TYPE, payload }) => {
    let products = state.cartContent.products;
    switch (TYPE) {
        case 'COLOR_UPDATE':
            const { selectedColor } = payload;
            const gallery = ClassonGallery[selectedColor];
            const productIcon = ClassonIcons[selectedColor];
            products[0].image = productIcon;
            products[0].title = `Classon Smart Helmet (Beta) - ${selectedColor}`;
            return {
                ...state,
                productContent: { ...state.productContent, gallery },
                cartContent: { ...state.cartContent, products },
                selectedColor,
            };
        case 'QUANTITY_UPDATE':
            const product = products[payload.index];

            if (product.disabled) return state;

            switch (payload.operator) {
                case '+':
                    products = products.map((product) => {
                        product.quantity += 1;
                        return product;
                    });
                    break;
                case '-':
                    if (product.quantity - 1 <= 0) {
                        return state;
                    }
                    products = products.map((product) => {
                        product.quantity -= 1;
                        return product;
                    });
                    break;
                default:
                    return state;
            }

            return {
                ...state,
                cartContent: { ...state.cartContent, products },
            };
        case 'TOGGLE_SHOW_BILLING':
            return { ...state, billingSameAsShipping: !state.billingSameAsShipping };
        default:
            return state;
    }
};
