import React from 'react';
import logo_nav from '../assets/img/logo_nav.png';
import CONSTANTS from '../constants/constant';

export default function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light c-navbar">
            <a className="navbar-brand" href={CONSTANTS.homeUrl}>
                <img src={logo_nav} alt={'logo'} />
            </a>
        </nav>
    );
}
