export const BV1ResReducer = (state, { TYPE, payload }) => {
    switch (TYPE) {
        case 'QUANTITY_UPDATE':
            let products = state.cartContent.products;
            const product = products[payload.index];

            if (product.disabled) return state;

            switch (payload.operator) {
                case '+':
                    products = products.map((product) => {
                        product.quantity += 1;
                        return product;
                    });
                    break;
                case '-':
                    if (product.quantity - 1 <= 0) {
                        return state;
                    }
                    products = products.map((product) => {
                        product.quantity -= 1;
                        return product;
                    });
                    break;
                default:
                    return state;
            }

            return {
                ...state,
                cartContent: { ...state.cartContent, products },
            };
        default:
            return state;
    }
};
