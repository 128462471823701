import React from 'react';

import { CartItems } from './Items';
import { SV1SummerSaleGallery } from './gallery';

const subtitleStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
};

export const SV1SummerSalePageContent = {
    title: 'SV1 Electric Scooter',
    productContent: {
        subtitle: (
            <>
                <div style={{ ...subtitleStyle, color: 'red' }}>Summer Sale - 30% OFF</div>
                <div style={subtitleStyle}>now $49/mo. for first 6 months</div>
            </>
        ),
        gallery: SV1SummerSaleGallery,
        description: [
            'The SV1 Electric Scooter integrates seamlessly with public transportation and comes Quorra-ready. With 15 miles of range per battery, 20 mph top speed, airless tires, and weighing less than 26 lbs – it easily folds so you can travel wherever the road takes you. There is a one time set-up fee of $50 for new riders.',
        ],
        highlights: [
            'month-to-month; cancel anytime',
            'VIP same-day service & maintenance',
            'Ready for pickup/shipping',
        ],
        footerHighlights: [
            'Smart Lock',
            '24/7 Support',
            'Local Maintenance',
            'Free scooter repair or swap, ',
            'Theft Insurance',
            'GPS Tracking',
            'Exclusive member events and rides',
            'Member-only discounts from top brands (coming soon)',
        ],
    },
    cartContent: {
        products: [
            CartItems.Products.SV1SummerPreSale,
            CartItems.Products.SetupFee,
            CartItems.Products.BeyondPremiereSubscription,
            // CartItems.Products.SV1ExtraBattery,
        ],
        footerContent: CartItems.Footer.SV1PickUpFooter,
    },
    showShipping: true,
    billingSameAsShipping: true,
    allowRefer: true,
    paymentNote: [
        '* We only accept debit/credit cards. We do not accept pre-paid cards.',
        '** If you are renting 2 or more scooters, you must use a credit card.',
    ],
};
