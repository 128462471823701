export const appStateReducer = (state, { TYPE, payload }) => {
    // console.log(payload);
    // console.log(TYPE);
    // console.log(state);
    let { appState } = state;

    switch (TYPE) {
        case 'ERROR_MESSAGE':
            return { ...state, appState: { ...appState, errorMessage: payload.message } };
        case 'PAYMENT_SUCCESS':
            return { ...state, appState: { ...appState, paymentSuccess: payload.paymentStatus } };
        case 'TOGGLE_NEWS_SUBSCRIPTION':
            return {
                ...state,
                appState: { ...appState, newsletterSubscribed: !appState.newsletterSubscribed },
            };
        case 'UPDATE_BILLING':
            appState = {
                ...appState,
                billing: { ...appState.billing, [payload.field]: payload.value },
            };
            return { ...state, appState };
        case 'UPDATE_ACCOUNT':
            appState = {
                ...appState,
                account: { ...appState.account, [payload.field]: payload.value },
            };
            return { ...state, appState };
        case 'UPDATE_NAME':
            appState = { ...appState, [payload.field]: payload.value };
            return { ...state, appState };
        case 'UPDATE_LOADING':
            appState = { ...appState, loading: payload.loading };
            return { ...state, appState };
        case 'UPDATE_TOTAL':
            appState = { ...appState, total: payload.total };
            return { ...state, appState };
        case 'COUPON_DISCOUNT':
            appState = { ...appState, couponDiscount: payload.couponDiscount };
            return { ...state, appState };
        case 'UPDATE_COUPON':
            appState = {
                ...appState,
                billing: { ...appState.billing, [payload.field]: payload.value },
            };
            return { ...state, appState };
        case 'UPDATE_COUPON_CODE':
            appState = {
                ...appState,
                billing: { ...appState.billing, couponCode: payload.couponCode },
            };
            return { ...state, appState };
        default:
            return state;
    }
};
