import React, { useState, useEffect } from 'react';
import { Spin, notification } from 'antd';
import Persona from 'persona';
import queryString from 'query-string';

import CONSTANTS from '../constants/constant';

const VerifyPage = () => {
    const [email, setEmail] = useState(null);

    useEffect(() => {
        document.body.style.backgroundColor = '#505060';

        const { email } = queryString.parse(location.search);

        if (email) {
            setEmail(email.replace(' ', '+'));
        } else {
            notification.error({
                message: 'Invalid request',
                onClose: () => (window.location.href = CONSTANTS.homeUrl),
            });
        }
        return;
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '50px',
                height: '100vh',
            }}
        >
            {!!email ? (
                <Persona.Inquiry
                    environment={process.env.REACT_APP_STAGE === 'prod' ? 'production' : 'sandbox'}
                    templateId={CONSTANTS.PersonaTemplateId}
                    prefill={{ emailAddress: email }}
                    onLoad={(error) => {
                        if (error) {
                            notification.error({ message: error ?? 'An error has occurred.' });
                            return;
                        }
                        console.log('Loaded inline');
                    }}
                    onStart={(inquiryId) => {
                        console.log(`Started inquiry ${inquiryId}`);
                    }}
                    onComplete={(inquiryId) => {
                        console.log(`Verification complete: ${inquiryId}`);
                        window.location.href = CONSTANTS.homeUrl;
                    }}
                />
            ) : (
                <Spin spinning={!email} size={'large'}></Spin>
            )}
        </div>
    );
};

export default VerifyPage;
