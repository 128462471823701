import React, { useContext } from 'react';

import { AppContext } from '../../AppContext';

export default function ProductFooter() {
    const {
        productState: {
            productContent: { footerHighlights },
        },
    } = useContext(AppContext);

    return (
        <>
            {footerHighlights && (
                <>
                    <div className="c-infopage__description-title mt-3">Now Includes</div>

                    <div className="c-infopage__promo">
                        <div className="d-flex justify-content-between c-infopage__promo-title">
                            <div className="">Beyond Premiere</div>
                            <div className="c-infopage__promo-title-right">
                                {/* <p style={{ fontWeight: 'bold' }}>Free! for 6 months</p> */}
                                {/* <p>normally $19/mo.</p> */}
                            </div>
                        </div>

                        {footerHighlights.map((highlight, index) => (
                            <div key={index} className="c-infopage__promo-point">
                                {' '}
                                * {highlight}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    );
}
