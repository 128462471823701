import React, { useContext } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import { AppContext } from '../../AppContext';
import { formInputFormatter } from '../../utils';

export default function PaymentForm() {
    const {
        appState: { firstName, lastName },
        appDispatch,
    } = useContext(AppContext);

    const updateBilling = (e) => {
        const [field, value] = formInputFormatter(e.target.name, e.target.value);

        appDispatch({
            TYPE: 'UPDATE_NAME',
            payload: { field, value },
        });
    };

    const style = {
        base: {
            lineHeight: '22px',
            '::placeholder': {
                color: '#ABACB0',
            },
        },
    };

    return (
        <div className=" col-lg-6 col-xs-12">
            <CardNumberElement
                className="c-input"
                options={{
                    style,
                }}
            />
            <div className="row c-card-payment__exp">
                <div className="col-6" style={{ paddingRight: '10px' }}>
                    <input
                        pattern=".*\S+.*"
                        name="firstName"
                        className="c-input"
                        placeholder="First Name"
                        value={firstName}
                        onChange={updateBilling}
                    />
                </div>
                <div className="col-6" style={{ paddingLeft: '10px' }}>
                    <input
                        name="lastName"
                        className="c-input"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={updateBilling}
                    />
                </div>
            </div>
            <div className="row c-card-payment__exp">
                <div className="col-6" style={{ paddingRight: '10px' }}>
                    <CardExpiryElement
                        className="c-input"
                        options={{
                            style,
                        }}
                    />
                </div>
                <div className="col-6" style={{ paddingLeft: '10px' }}>
                    <CardCvcElement
                        className="c-input"
                        options={{
                            style,
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
