import React, { useContext } from 'react';
import { Card } from 'antd';

import { AppContext } from '../../AppContext';
import { CartProcessIcon } from '../../assets/Icons/CustomIcons';
import ShoppingCartItem from './Components/ShoppingCartItem';
import CartFooter from './Components/CartFooter';

export default function ShoppingCart() {
    const {
        productState: { cartContent },
    } = useContext(AppContext);

    return (
        <Card
            title={<div className="c-card__title">Shopping Cart</div>}
            bordered={false}
            extra={<CartProcessIcon />}
            className="c-card border-0"
        >
            <div className="row c-card__list">
                <div className="col-7">Product Info</div>
                <div className="col-3">Quantity</div>
                <div className="col-2 text-right">Price</div>
            </div>

            {cartContent.products.map((item, index) => (
                <ShoppingCartItem key={index} index={index} item={item} />
            ))}

            <CartFooter />
        </Card>
    );
}
