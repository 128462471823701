const CONSTANTS = {
    // Links
    homeUrl: 'https://ridebeyond.com',
    SV1OnboardingLink:
        'https://help.ridebeyond.com/en/articles/4576251-quick-start-guide-sv1-electric-scooter',
    ClassonOnboardingLink:
        'https://help.ridebeyond.com/en/articles/4576252-quick-start-guide-classon-smart-helmet',
    BV1OnboardingLink: '',
    iPhoneAppLink_NEW: 'https://apps.apple.com/app/id1543945606',
    iPhoneAppLink_LEGACY: 'https://apps.apple.com/us/app/beyond-micromobility/id1465352403',
    AndroidAppLink: 'https://play.google.com/store/apps/details?id=com.brooklyness.classon',

    // Stripe
    TAX_NY: 0.08875,

    // Persona
    PersonaTemplateId: 'tmpl_JjR5aDFTa9ipNRPpEEmm6y7A',
};

export default CONSTANTS;
