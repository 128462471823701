import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import {
    ClassonBetaPageContent,
    BV1ResPageContent,
    SV1SubPageContent,
    //SV1SummerSalePageContent,
} from './PageContent';
import { ClassonReducer, BV1ResReducer, SV1SubReducer } from './Reducer';
import ContextRoute from './ContextRoute';
import VerifyPage from './Components/VerifyPage';

export default function AppRoute() {
    return (
        <Router>
            <Switch>
                <ContextRoute
                    exact
                    path="/"
                    productReducer={SV1SubReducer}
                    productContent={SV1SubPageContent}
                />

                <ContextRoute
                    exact
                    path="/classon-beta"
                    productReducer={ClassonReducer}
                    productContent={ClassonBetaPageContent}
                />

                <ContextRoute
                    exact
                    path="/bv1-reservation"
                    productReducer={BV1ResReducer}
                    productContent={BV1ResPageContent}
                />

                <ContextRoute
                    exact
                    path="/sv1-subscription"
                    productReducer={SV1SubReducer}
                    productContent={SV1SubPageContent}
                />

                <Route exact path="/verify">
                    <VerifyPage />
                </Route>

                <Redirect to="/" />
            </Switch>
        </Router>
    );
}
