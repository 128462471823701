import React, { useContext } from 'react';
import { Card } from 'antd';

import { AppContext } from '../../AppContext';
import { formInputFormatter } from '../../utils';
import { BillingProcessIcon } from '../../assets/Icons/CustomIcons';

export default function RiderCard() {
    const {
        appState: { newsletterSubscribed, billing },
        appDispatch,
    } = useContext(AppContext);

    const updateBilling = (e) => {
        const [field, value] = formInputFormatter(e.target.name, e.target.value);

        appDispatch({
            TYPE: 'UPDATE_BILLING',
            payload: { field, value },
        });
    };

    return (
        <Card
            title={<div className="c-card__title">Rider Info</div>}
            bordered={false}
            extra={<BillingProcessIcon />}
            className="c-card border-0"
        >
            <div className="pb-3">
                <div className="row c-card__input ">
                    <div className="col-6">
                        <input
                            className="c-input"
                            placeholder="Email"
                            name="email"
                            value={billing.email}
                            onChange={updateBilling}
                        />
                    </div>
                    <div className="col-6">
                        <input
                            name="zipCode"
                            type="number"
                            className="c-input"
                            placeholder="Zip Code"
                            value={billing.zipCode}
                            onChange={updateBilling}
                        />
                    </div>

                    <div className=" col-12 mt-3">
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                id="checkbox"
                                name="newsletterSubscribed"
                                checked={newsletterSubscribed}
                                onChange={() =>
                                    appDispatch({
                                        TYPE: 'TOGGLE_NEWS_SUBSCRIPTION',
                                        payload: {},
                                    })
                                }
                            />
                            <label htmlFor="checkbox">
                                <span>subscribe to newsletter</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}
