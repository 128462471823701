import React from 'react';
import Icon from '@ant-design/icons';

const greenFill = '#00CD98';
const purpleFill = '#5351FB';
const greyFill = '#C3C6D1';

const HeartSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
        <path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" />
    </svg>
);
const ShoppingExtraSvg = () => (
    <svg
        width="108"
        height="24"
        viewBox="0 0 108 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 4.56205L7.76825 9.64018C7.41468 10.0645 6.78412 10.1218 6.35984 9.76822C5.93556 9.41466 5.87824 8.78409 6.2318 8.35982L11.2318 2.35982C11.6316 1.88006 12.3685 1.88006 12.7682 2.35982L17.7682 8.35982C18.1218 8.78409 18.0645 9.41466 17.6402 9.76822C17.2159 10.1218 16.5854 10.0645 16.2318 9.64018L12 4.56205Z"
            fill="#5351FB"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.50006 9C2.78648 9 2.30253 9.72593 2.57699 10.3846L6.23083 19.1538C6.69664 20.2718 7.78896 21 9.00006 21H15.0001C16.2112 21 17.3035 20.2718 17.7693 19.1538L21.4231 10.3846C21.6976 9.72593 21.2136 9 20.5001 9H3.50006ZM12.0001 17C13.1046 17 14.0001 16.1046 14.0001 15C14.0001 13.8954 13.1046 13 12.0001 13C10.8955 13 10.0001 13.8954 10.0001 15C10.0001 16.1046 10.8955 17 12.0001 17Z"
            fill="#5351FB"
        />
        <rect x="39" y="11" width="30" height="2" rx="1" fill="#E6E8EE" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 7.64987C105 7.25468 104.767 6.89656 104.406 6.73606L101.161 5.29366L93.6 9.49408V13.9057L91.6 12.9057V8.31728L98.8728 4.27681L96.4061 3.1805C96.1476 3.06559 95.8524 3.06559 95.5939 3.1805L87.5939 6.73606C87.2327 6.89656 87 7.25468 87 7.64987V16.4116C87 16.7748 87.1969 17.1094 87.5144 17.2858L95.5144 21.7302C95.8164 21.898 96.1836 21.898 96.4856 21.7302L104.486 17.2858C104.803 17.1094 105 16.7748 105 16.4116V7.64987Z"
            fill="#C3C6D1"
        />
    </svg>
);

const DeliveryExtraSvg = () => (
    <svg
        width="108"
        height="24"
        viewBox="0 0 108 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 4.56205L7.76825 9.64018C7.41468 10.0645 6.78412 10.1218 6.35984 9.76822C5.93556 9.41466 5.87824 8.78409 6.2318 8.35982L11.2318 2.35982C11.6316 1.88006 12.3685 1.88006 12.7682 2.35982L17.7682 8.35982C18.1218 8.78409 18.0645 9.41466 17.6402 9.76822C17.2159 10.1218 16.5854 10.0645 16.2318 9.64018L12 4.56205Z"
            fill="#00CD98"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.50006 9C2.78648 9 2.30253 9.72593 2.57699 10.3846L6.23083 19.1538C6.69664 20.2718 7.78896 21 9.00006 21H15.0001C16.2112 21 17.3035 20.2718 17.7693 19.1538L21.4231 10.3846C21.6976 9.72593 21.2136 9 20.5001 9H3.50006ZM12.0001 17C13.1046 17 14.0001 16.1046 14.0001 15C14.0001 13.8954 13.1046 13 12.0001 13C10.8955 13 10.0001 13.8954 10.0001 15C10.0001 16.1046 10.8955 17 12.0001 17Z"
            fill="#00CD98"
        />
        <rect x="39" y="11" width="30" height="2" rx="1" fill="#00CD98" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 7.64989C105 7.2547 104.767 6.89657 104.406 6.73607L101.161 5.29368L93.6 9.4941V13.9057L91.6 12.9057V8.31729L98.8728 4.27683L96.4061 3.18052C96.1476 3.0656 95.8524 3.0656 95.5939 3.18052L87.5939 6.73607C87.2327 6.89657 87 7.2547 87 7.64989V16.4116C87 16.7748 87.1969 17.1094 87.5144 17.2858L95.5144 21.7302C95.8164 21.898 96.1836 21.898 96.4856 21.7302L104.486 17.2858C104.803 17.1094 105 16.7748 105 16.4116V7.64989Z"
            fill="#5351FB"
        />
    </svg>
);

const CartPurpSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 4.56205L7.76825 9.64018C7.41468 10.0645 6.78412 10.1218 6.35984 9.76822C5.93556 9.41466 5.87824 8.78409 6.2318 8.35982L11.2318 2.35982C11.6316 1.88006 12.3685 1.88006 12.7682 2.35982L17.7682 8.35982C18.1218 8.78409 18.0645 9.41466 17.6402 9.76822C17.2159 10.1218 16.5854 10.0645 16.2318 9.64018L12 4.56205Z"
            fill="#5351FB"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.50006 9C2.78648 9 2.30253 9.72593 2.57699 10.3846L6.23083 19.1538C6.69664 20.2718 7.78896 21 9.00006 21H15.0001C16.2112 21 17.3035 20.2718 17.7693 19.1538L21.4231 10.3846C21.6976 9.72593 21.2136 9 20.5001 9H3.50006ZM12.0001 17C13.1046 17 14.0001 16.1046 14.0001 15C14.0001 13.8954 13.1046 13 12.0001 13C10.8955 13 10.0001 13.8954 10.0001 15C10.0001 16.1046 10.8955 17 12.0001 17Z"
            fill="#5351FB"
        />
    </svg>
);
const ShippingSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 7.64987C21 7.25468 20.7673 6.89656 20.4061 6.73606L17.1608 5.29366L9.6 9.49408V13.9057L7.6 12.9057V8.31728L14.8728 4.27681L12.4061 3.1805C12.1476 3.06559 11.8524 3.06559 11.5939 3.1805L3.59386 6.73606C3.23273 6.89656 3 7.25468 3 7.64987V16.4116C3 16.7748 3.19689 17.1094 3.51436 17.2858L11.5144 21.7302C11.8164 21.898 12.1836 21.898 12.4856 21.7302L20.4856 17.2858C20.8031 17.1094 21 16.7748 21 16.4116V7.64987Z"
            fill="#C3C6D1"
        />
    </svg>
);
const CartGreenSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 4.56205L7.76825 9.64018C7.41468 10.0645 6.78412 10.1218 6.35984 9.76822C5.93556 9.41466 5.87824 8.78409 6.2318 8.35982L11.2318 2.35982C11.6316 1.88006 12.3685 1.88006 12.7682 2.35982L17.7682 8.35982C18.1218 8.78409 18.0645 9.41466 17.6402 9.76822C17.2159 10.1218 16.5854 10.0645 16.2318 9.64018L12 4.56205Z"
            fill="#00CD98"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.50006 9C2.78648 9 2.30253 9.72593 2.57699 10.3846L6.23083 19.1538C6.69664 20.2718 7.78896 21 9.00006 21H15.0001C16.2112 21 17.3035 20.2718 17.7693 19.1538L21.4231 10.3846C21.6976 9.72593 21.2136 9 20.5001 9H3.50006ZM12.0001 17C13.1046 17 14.0001 16.1046 14.0001 15C14.0001 13.8954 13.1046 13 12.0001 13C10.8955 13 10.0001 13.8954 10.0001 15C10.0001 16.1046 10.8955 17 12.0001 17Z"
            fill="#00CD98"
        />
    </svg>
);
const ShoppingSvg = () => (
    <svg width="22" height="28" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 27.7342L24.1275 6.98158C24.0938 6.5709 23.7435 6.25957 23.3192 6.25957H19.372C19.3653 2.80853 16.5093 0 13 0C9.49067 0 6.63472 2.80853 6.62798 6.25957H2.68083C2.26321 6.25957 1.91295 6.5709 1.87254 6.98158L0 27.7342V27.8071C0 30.1188 2.16218 32 4.81606 32H21.1839C23.8378 32 26 30.1188 26 27.8071V27.7342ZM13 1.58973C15.6202 1.58973 17.7487 3.68288 17.7554 6.25957H8.24456C8.2513 3.68288 10.3798 1.58973 13 1.58973ZM4.81606 30.4036H21.1839C22.9352 30.4036 24.3632 29.2577 24.3834 27.8402L22.5782 7.84931H19.372V10.638C19.372 11.0751 19.0083 11.4328 18.5637 11.4328C18.1192 11.4328 17.7554 11.0751 17.7554 10.638V7.84931H8.24456V10.638C8.24456 11.0751 7.88083 11.4328 7.43627 11.4328C6.99171 11.4328 6.62798 11.0751 6.62798 10.638V7.84931H3.42176L1.61658 27.8336C1.63679 29.2577 3.06477 30.4036 4.81606 30.4036Z"
            fill="black"
        />
    </svg>
);
const CheckSvg = () => (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0001 19.5806C14.6025 19.5806 18.3334 15.609 18.3334 10.7097C18.3334 5.81035 14.6025 1.83868 10.0001 1.83868C5.39771 1.83868 1.66675 5.81035 1.66675 10.7097C1.66675 15.609 5.39771 19.5806 10.0001 19.5806ZM15.152 6.9563C14.8156 6.62201 14.2882 6.64137 13.9742 6.99953L8.68591 13.0311L6.37574 10.9232C6.0263 10.6044 5.50022 10.6475 5.2007 11.0194C4.90118 11.3914 4.94165 11.9514 5.29109 12.2703L8.20776 14.9316C8.54679 15.2409 9.05462 15.2108 9.35929 14.8633L15.1926 8.2101C15.5067 7.85193 15.4885 7.29059 15.152 6.9563Z"
            fill="#FD0000"
        />
    </svg>
);
const ShoppingSvg2 = () => (
    <svg
        width="192"
        height="24"
        viewBox="0 0 192 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 4.56205L7.76825 9.64018C7.41468 10.0645 6.78412 10.1218 6.35984 9.76822C5.93556 9.41466 5.87824 8.78409 6.2318 8.35982L11.2318 2.35982C11.6316 1.88006 12.3685 1.88006 12.7682 2.35982L17.7682 8.35982C18.1218 8.78409 18.0645 9.41466 17.6402 9.76822C17.2159 10.1218 16.5854 10.0645 16.2318 9.64018L12 4.56205Z"
            fill="#00CD98"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.50006 9C2.78648 9 2.30253 9.72593 2.57699 10.3846L6.23083 19.1538C6.69664 20.2718 7.78896 21 9.00006 21H15.0001C16.2112 21 17.3035 20.2718 17.7693 19.1538L21.4231 10.3846C21.6976 9.72593 21.2136 9 20.5001 9H3.50006ZM12.0001 17C13.1046 17 14.0001 16.1046 14.0001 15C14.0001 13.8954 13.1046 13 12.0001 13C10.8955 13 10.0001 13.8954 10.0001 15C10.0001 16.1046 10.8955 17 12.0001 17Z"
            fill="#00CD98"
        />
        {/* First Divider */}
        <rect x="39" y="11" width="30" height="2" rx="1" fill="#E6E8EE" />
        {/* Second Divider */}
        <rect x="123" y="11" width="30" height="2" rx="1" fill="#E6E8EE" />

        {/* Billing */}
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M170 7C170 5.89543 170.895 5 172 5H188C189.105 5 190 5.89543 190 7V8H170V7ZM170 11H190V17C190 18.1046 189.105 19 188 19H172C170.895 19 170 18.1046 170 17V11ZM185 14C184.448 14 184 14.4477 184 15C184 15.5523 184.448 16 185 16H187C187.552 16 188 15.5523 188 15C188 14.4477 187.552 14 187 14H185Z"
            fill="#C3C6D1"
        />
        {/* Card */}
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 7.64992C105 7.25473 104.767 6.89661 104.406 6.7361L101.161 5.29371L93.6 9.49413V13.9057L91.6 12.9057V8.31732L98.8728 4.27686L96.4061 3.18055C96.1476 3.06563 95.8524 3.06563 95.5939 3.18055L87.5939 6.7361C87.2327 6.89661 87 7.25473 87 7.64992V16.4116C87 16.7748 87.1969 17.1094 87.5144 17.2858L95.5144 21.7302C95.8164 21.898 96.1836 21.898 96.4856 21.7302L104.486 17.2858C104.803 17.1094 105 16.7748 105 16.4116V7.64992Z"
            fill="#C3C6D1"
        />
    </svg>
);
const VisaSvg = () => (
    <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.153 0.799011L16.722 15.065H20.61L23.043 0.799011H19.153Z" fill="white" />
        <path
            d="M13.4621 0.815012L9.65406 10.544L9.24806 9.07501C8.49706 7.30501 6.36506 4.76301 3.86206 3.16101L7.34406 15.058L11.4581 15.051L17.5811 0.812012L13.4621 0.815012Z"
            fill="white"
        />
        <path
            d="M7.772 1.836C7.546 0.967002 6.891 0.708002 6.078 0.677002H0.05L0 0.961002C4.691 2.099 7.795 4.841 9.083 8.138L7.772 1.836Z"
            fill="white"
        />
        <path
            d="M30.923 3.53402C32.195 3.51402 33.117 3.79202 33.833 4.08002L34.184 4.24502L34.71 1.15302C33.94 0.864016 32.733 0.554016 31.227 0.554016C27.385 0.554016 24.677 2.48902 24.656 5.26202C24.631 7.31102 26.585 8.45502 28.061 9.13802C29.576 9.83802 30.084 10.283 30.077 10.908C30.065 11.863 28.869 12.301 27.752 12.301C26.195 12.301 25.368 12.086 24.091 11.554L23.59 11.326L23.043 14.519C23.953 14.918 25.632 15.261 27.375 15.279C31.462 15.279 34.118 13.367 34.146 10.404C34.163 8.78302 33.126 7.54602 30.879 6.53102C29.519 5.86902 28.687 5.42902 28.695 4.76102C28.695 4.16802 29.401 3.53402 30.923 3.53402Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.684 0.815002H44.688L47.834 15.073H44.227C44.227 15.073 43.869 13.434 43.753 12.936C43.454 12.936 42.2021 12.9343 41.0233 12.9327C39.9749 12.9313 38.9844 12.93 38.774 12.93C38.623 13.315 37.957 15.073 37.957 15.073H33.874L39.647 1.999C40.057 1.069 40.751 0.815002 41.684 0.815002ZM41.442 6.023C41.442 6.023 40.213 9.191 39.893 10.01H43.123L42.222 5.891L41.96 4.661C41.8617 4.92367 41.7306 5.26459 41.6248 5.53978C41.5143 5.82719 41.4313 6.04293 41.442 6.023Z"
            fill="white"
        />
    </svg>
);

const CartProcessSvg = () => (
    <svg
        width="192"
        height="24"
        viewBox="0 0 192 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* Cart */}
        <path
            d="M12 4.56205L7.76825 9.64018C7.41468 10.0645 6.78412 10.1218 6.35984 9.76822C5.93556 9.41466 5.87824 8.78409 6.2318 8.35982L11.2318 2.35982C11.6316 1.88006 12.3685 1.88006 12.7682 2.35982L17.7682 8.35982C18.1218 8.78409 18.0645 9.41466 17.6402 9.76822C17.2159 10.1218 16.5854 10.0645 16.2318 9.64018L12 4.56205Z"
            fill={purpleFill}
        />

        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.50104 9C2.78746 9 2.30351 9.72593 2.57796 10.3846L6.23181 19.1538C6.69762 20.2718 7.78994 21 9.00104 21H15.001C16.2121 21 17.3045 20.2718 17.7703 19.1538L21.4241 10.3846C21.6986 9.72593 21.2146 9 20.501 9H3.50104ZM12.001 17C13.1056 17 14.001 16.1046 14.001 15C14.001 13.8954 13.1056 13 12.001 13C10.8965 13 10.001 13.8954 10.001 15C10.001 16.1046 10.8965 17 12.001 17Z"
            fill={purpleFill}
        />

        {/* First Divider */}
        <rect x="39" y="11" width="30" height="2" rx="1" fill={greyFill} />

        {/* Card */}
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M86 7C86 5.89543 86.8954 5 88 5H104C105.105 5 106 5.89543 106 7V8H86V7ZM86 11H106V17C106 18.1046 105.105 19 104 19H88C86.8954 19 86 18.1046 86 17V11ZM101 14C100.448 14 100 14.4477 100 15C100 15.5523 100.448 16 101 16H103C103.552 16 104 15.5523 104 15C104 14.4477 103.552 14 103 14H101Z"
            fill={greyFill}
        />

        {/* Second Divider */}
        <rect x="123" y="11" width="30" height="2" rx="1" fill={greyFill} />

        {/* Billing */}
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M189 7.64979C189 7.2546 188.767 6.89648 188.406 6.73598L185.161 5.29359L177.6 9.49401V13.9056L175.6 12.9056V8.3172L182.873 4.27674L180.406 3.18043C180.148 3.06551 179.852 3.06551 179.594 3.18043L171.594 6.73598C171.233 6.89648 171 7.2546 171 7.64979V16.4115C171 16.7747 171.197 17.1093 171.514 17.2857L179.514 21.7301C179.816 21.8979 180.184 21.8979 180.486 21.7301L188.486 17.2857C188.803 17.1093 189 16.7747 189 16.4115V7.64979Z"
            fill={greyFill}
        />
    </svg>
);

const BillingProcessSvg = () => (
    <svg
        width="192"
        height="24"
        viewBox="0 0 192 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* Cart */}
        <path
            d="M12 4.56205L7.76825 9.64018C7.41468 10.0645 6.78412 10.1218 6.35984 9.76822C5.93556 9.41466 5.87824 8.78409 6.2318 8.35982L11.2318 2.35982C11.6316 1.88006 12.3685 1.88006 12.7682 2.35982L17.7682 8.35982C18.1218 8.78409 18.0645 9.41466 17.6402 9.76822C17.2159 10.1218 16.5854 10.0645 16.2318 9.64018L12 4.56205Z"
            fill={greenFill}
        />

        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.50104 9C2.78746 9 2.30351 9.72593 2.57796 10.3846L6.23181 19.1538C6.69762 20.2718 7.78994 21 9.00104 21H15.001C16.2121 21 17.3045 20.2718 17.7703 19.1538L21.4241 10.3846C21.6986 9.72593 21.2146 9 20.501 9H3.50104ZM12.001 17C13.1056 17 14.001 16.1046 14.001 15C14.001 13.8954 13.1056 13 12.001 13C10.8965 13 10.001 13.8954 10.001 15C10.001 16.1046 10.8965 17 12.001 17Z"
            fill={greenFill}
        />

        {/* First Divider */}
        <rect x="39" y="11" width="30" height="2" rx="1" fill={greenFill} />

        {/* Card */}
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M86 7C86 5.89543 86.8954 5 88 5H104C105.105 5 106 5.89543 106 7V8H86V7ZM86 11H106V17C106 18.1046 105.105 19 104 19H88C86.8954 19 86 18.1046 86 17V11ZM101 14C100.448 14 100 14.4477 100 15C100 15.5523 100.448 16 101 16H103C103.552 16 104 15.5523 104 15C104 14.4477 103.552 14 103 14H101Z"
            fill={greenFill}
        />

        {/* Second Divider */}
        <rect x="123" y="11" width="30" height="2" rx="1" fill={greenFill} />

        {/* Billing */}
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M189 7.64979C189 7.2546 188.767 6.89648 188.406 6.73598L185.161 5.29359L177.6 9.49401V13.9056L175.6 12.9056V8.3172L182.873 4.27674L180.406 3.18043C180.148 3.06551 179.852 3.06551 179.594 3.18043L171.594 6.73598C171.233 6.89648 171 7.2546 171 7.64979V16.4115C171 16.7747 171.197 17.1093 171.514 17.2857L179.514 21.7301C179.816 21.8979 180.184 21.8979 180.486 21.7301L188.486 17.2857C188.803 17.1093 189 16.7747 189 16.4115V7.64979Z"
            fill={purpleFill}
        />
    </svg>
);

const PaymentProcessSvg = () => (
    <svg
        width="192"
        height="24"
        viewBox="0 0 192 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* Cart */}
        <path
            d="M12 4.56205L7.76825 9.64018C7.41468 10.0645 6.78412 10.1218 6.35984 9.76822C5.93556 9.41466 5.87824 8.78409 6.2318 8.35982L11.2318 2.35982C11.6316 1.88006 12.3685 1.88006 12.7682 2.35982L17.7682 8.35982C18.1218 8.78409 18.0645 9.41466 17.6402 9.76822C17.2159 10.1218 16.5854 10.0645 16.2318 9.64018L12 4.56205Z"
            fill={greenFill}
        />

        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.50104 9C2.78746 9 2.30351 9.72593 2.57796 10.3846L6.23181 19.1538C6.69762 20.2718 7.78994 21 9.00104 21H15.001C16.2121 21 17.3045 20.2718 17.7703 19.1538L21.4241 10.3846C21.6986 9.72593 21.2146 9 20.501 9H3.50104ZM12.001 17C13.1056 17 14.001 16.1046 14.001 15C14.001 13.8954 13.1056 13 12.001 13C10.8965 13 10.001 13.8954 10.001 15C10.001 16.1046 10.8965 17 12.001 17Z"
            fill={greenFill}
        />

        {/* First Divider */}
        <rect x="39" y="11" width="30" height="2" rx="1" fill={greenFill} />

        {/* Card */}
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M86 7C86 5.89543 86.8954 5 88 5H104C105.105 5 106 5.89543 106 7V8H86V7ZM86 11H106V17C106 18.1046 105.105 19 104 19H88C86.8954 19 86 18.1046 86 17V11ZM101 14C100.448 14 100 14.4477 100 15C100 15.5523 100.448 16 101 16H103C103.552 16 104 15.5523 104 15C104 14.4477 103.552 14 103 14H101Z"
            fill={purpleFill}
        />

        {/* Second Divider */}
        <rect x="123" y="11" width="30" height="2" rx="1" fill={greyFill} />

        {/* Billing */}
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M189 7.64979C189 7.2546 188.767 6.89648 188.406 6.73598L185.161 5.29359L177.6 9.49401V13.9056L175.6 12.9056V8.3172L182.873 4.27674L180.406 3.18043C180.148 3.06551 179.852 3.06551 179.594 3.18043L171.594 6.73598C171.233 6.89648 171 7.2546 171 7.64979V16.4115C171 16.7747 171.197 17.1093 171.514 17.2857L179.514 21.7301C179.816 21.8979 180.184 21.8979 180.486 21.7301L188.486 17.2857C188.803 17.1093 189 16.7747 189 16.4115V7.64979Z"
            fill={greyFill}
        />
    </svg>
);

export const HeartIcon = (props) => <Icon component={HeartSvg} {...props} />;
export const CartPurpIcon = (props) => <Icon component={CartPurpSvg} {...props} />;
export const ShippingIcon = (props) => <Icon component={ShippingSvg} {...props} />;
export const CartGreenIcon = (props) => <Icon component={CartGreenSvg} {...props} />;

export const ShoppingIcon = (props) => <Icon component={ShoppingSvg} {...props} />;
export const ShippingIcon2 = (props) => <Icon component={ShoppingSvg2} {...props} />;
export const ShoppingExtraIcon = (props) => <Icon component={ShoppingExtraSvg} {...props} />;
export const DeliveryExtraIcon = (props) => <Icon component={DeliveryExtraSvg} {...props} />;
export const CheckIcon = (props) => <Icon component={CheckSvg} {...props} />;
export const VisaIcon = (props) => <Icon component={VisaSvg} {...props} />;
export const CartProcessIcon = (props) => <Icon component={CartProcessSvg} {...props} />;
export const BillingProcessIcon = (props) => <Icon component={BillingProcessSvg} {...props} />;
export const PaymentProcessIcon = (props) => <Icon component={PaymentProcessSvg} {...props} />;
