const generatePaymentItems = (products) => {
    return products.reduce((pre, cur) => {
        const items = pre;
        const item = {
            priceId: cur.priceId,
            quantity: cur.quantity,
            metadata: {
                title: cur.title,
            },
        };
        items.push(item);
        return items;
    }, []);
};

const formInputFormatter = (field, value) => {
    if (!value) return [field, value];

    let formattedValue = value.toLowerCase();

    if (field === 'firstName' || field === 'lastName') {
        formattedValue = formattedValue[0].toUpperCase() + formattedValue.substring(1);
    }
    if (field === 'promotionCode') {
        formattedValue = formattedValue.toUpperCase().trim();
    }
    if (field === 'phone') {
        formattedValue = formattedValue.substring(0, 10);
    }

    // if (field === 'email') {
    //     formattedValue = formattedValue.toLowerCase();
    // }

    if (field === 'city' || field === 'line1' || field === 'line2') {
        formattedValue = formattedValue
            .split(' ')
            .map((word) => {
                if (!word) return;
                return word[0].toUpperCase() + word.substring(1);
            })
            .join(' ');
    }

    if (field === 'state') {
        formattedValue = formattedValue.substring(0, 2).toUpperCase();
    }

    if (field === 'zipCode') {
        formattedValue = formattedValue.substring(0, 5);
    }

    return [field, formattedValue];
};

export { generatePaymentItems, formInputFormatter };
