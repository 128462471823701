import React, { useContext } from 'react';

import { AppContext } from '../AppContext';
import CONSTANTS from '../constants/constant';

export default function SuccessPayment() {
    const {
        productState: { title },
    } = useContext(AppContext);

    let iPhoneAppLink, onboardingLink;

    if (title.includes('SV1')) {
        iPhoneAppLink = CONSTANTS.iPhoneAppLink_NEW;
        onboardingLink = CONSTANTS.SV1OnboardingLink;
    } else if (title.includes('Classon')) {
        iPhoneAppLink = CONSTANTS.iPhoneAppLink_LEGACY;
        onboardingLink = CONSTANTS.ClassonOnboardingLink;
    }

    return (
        <div id="right-side-success" className=" transit1 c-checkout__right-side-success">
            <div className="c-checkout__right-side-success-title">
                Your reservation has successfully been made!
            </div>
            <div className="c-checkout__right-side-success-subtitle">What Next?</div>
            <div className="c-checkout__right-side-success-content">
                1. Download the
                <a href={iPhoneAppLink} target="_blank" rel="noopener noreferrer">
                    {' '}
                    Beyond iPhone app
                </a>{' '}
                or{' '}
                <a href={CONSTANTS.AndroidAppLink} target="_blank" rel="noopener noreferrer">
                    {' '}
                    Beyond Android app
                </a>
            </div>
            <div className="c-checkout__right-side-success-content">
                2. Check your email to begin your{' '}
                <a href={onboardingLink} target="_blank" rel="noopener noreferrer">
                    {' '}
                    onboarding
                </a>
            </div>
        </div>
    );
}
