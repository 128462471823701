import React from 'react';

import { SV1Gallery, ClassonIcons, BV1Gallery } from './gallery';
// SV1 Asset
import BK_1 from '../assets/img/img1.png';
import logo2 from '../assets/img/logo2.png';
import CreditCard from '../assets/img/CreditCard.png';
import BatteryIcon from '../assets/img/Product/BatteryIcon.png';

export const PRICE_ID = {
    BeyondPremiereFree: process.env.REACT_APP_BEYOND_PREMIERE_FREE_PRICE_ID,
    BeyondPremiereSubscription: process.env.REACT_APP_BEYOND_PREMIERE_SUB_PRICE_ID,
    SV1Reservation: process.env.REACT_APP_SV1_RESERVATION_PRICE_ID,
    SV1NoSetUpFee: process.env.REACT_APP_SV1_NO_SETUP_FEE_PRICE_ID,
    SV1SpringPromo: process.env.REACT_APP_SV1_SPRING_PROMO_PRICE_ID,
    SV1MarchPromo: process.env.REACT_APP_SV1_MARCH_PROMO_PRICE_ID,
    ClassonBeta: process.env.REACT_APP_CLASSON_BETA_PRICE_ID,
    BV1Reservation: process.env.REACT_APP_BV1_RESERVATION_PRICE_ID,
    SV1Subscription: process.env.REACT_APP_SV1_SUBSCRIPTION_PRICE_ID,
    SV1ExtraBattery: process.env.REACT_APP_SV1_BATTERY_PRICE_ID,
    SetupFee: process.env.REACT_APP_SETUP_FEE_PRICE_ID,
};

export const SV1ReserveProductContent = {
    title: 'SV1 Electric Scooter',
    subtitle: (
        <>
            <div className="c-infopage__subtitle font-weight-bold">
                <a href="#right-side">
                    <span className="web-view">Reserve now for $1</span>
                    {/* <span className="mobile-view">Reserve now for $1</span> */}
                </a>
                {'  '}(arrives in early April)
            </div>
            <p>$49/mo. plan + Beyond Premiere free for 6 months</p>
        </>
    ),
    gallery: SV1Gallery,
    description: [
        'The SV1 Electric Scooter integrates seamlessly with public transportation and comes Quorra-ready. With 15 miles of range per battery, 20 mph top speed, airless tires, and weighing less than 26 lbs – it easily folds so you can travel wherever the road takes you. There is a one time set-up fee of $50 for new riders.',
    ],
    highlights: [
        'month-to-month; cancel anytime',
        'VIP same-day service & maintenance',
        'arrives in early April',
    ],
    showFooter: true,
};

export const CartItems = {
    Products: {
        SV1Reservation: {
            title: 'Reservation Fee ($1) - shipping in early April',
            body: ['$69/mo. plan', '$99 one-time set-up'],
            image: BK_1,
            cost: 1,
            quantity: 1,
            priceId: PRICE_ID.SV1Reservation,
            disabled: false,
        },
        BeyondPremiereFree: {
            title: 'Beyond Premiere    ',
            body: [
                'Smart Lock',
                '24/7 Support',
                'Local Maintenance',
                'Theft Insurance',
                'GPS Tracking',
                'Exclusive member events and rides',
                'Member-only discounts from top brands (coming soon)',
            ],
            image: CreditCard,
            cost: 0,
            quantity: 1,
            priceId: PRICE_ID.BeyondPremiereFree,
            disabled: true,
        },
        BeyondPremiereSubscription: {
            title: 'Beyond Premiere  ',
            body: [
                'Smart Lock',
                '24/7 Support',
                'Local Maintenance',
                'Theft Insurance',
                'GPS Tracking',
                'Exclusive member events and rides',
                'Member-only discounts from top brands (coming soon)',
            ],
            image: CreditCard,
            cost: 0,
            quantity: 1,
            priceId: PRICE_ID.BeyondPremiereSubscription,
            disabled: true,
        },
        SV1NoSetupFee: {
            title: 'Reservation Fee ($1) - shipping in early April',
            body: ['$49/mo. plan'],
            image: BK_1,
            cost: 1,
            quantity: 1,
            priceId: PRICE_ID.SV1NoSetUpFee,
            disabled: false,
        },
        SpringPromo: {
            title: 'Spring Promotion - $0 set-up fee (normally $50)',
            body: [],
            image: logo2,
            cost: 0,
            quantity: 1,
            priceId: PRICE_ID.SV1SpringPromo,
            disabled: true,
        },
        MarchPromo: {
            title: 'Spring Promotion - One free month* ($49 value)',
            body: [],
            image: logo2,
            cost: 0,
            quantity: 1,
            priceId: PRICE_ID.SV1MarchPromo,
            disabled: true,
        },
        SV1Subscription: {
            title: 'SV1 Electric Scooter - monthly subscription',
            body: ['$69/mo. plan', '$99 one-time set-up'],
            image: BK_1,
            cost: 168,
            quantity: 1,
            priceId: PRICE_ID.SV1Subscription,
            disabled: false,
        },
        SV1SummerPreSale: {
            title: 'Summer Sale - (30% OFF)',
            body: [
                'SV1 Electric Scooter - monthly subscription',
                '$49/mo. plan',
                '$50 one-time set-up',
            ],
            image: BK_1,
            cost: 99,
            quantity: 1,
            priceId: PRICE_ID.SV1Subscription,
            disabled: false,
        },
        SV1ExtraBattery: {
            title: 'Extra SV1 Battery - for unlimited range',
            body: ['$19/mo', 'max 2 extra batteries allowed / scooter'],
            image: BatteryIcon,
            cost: 19,
            quantity: 0,
            priceId: PRICE_ID.SV1ExtraBattery,
            disabled: false,
        },
        SetupFee: {
            cost: 0,
            quantity: 1,
            priceId: PRICE_ID.SetupFee,
        },
        ClassonBeta: {
            title: 'Classon Smart Helmet (Beta) - Blue',
            body: [
                '24/7 support',
                'Free helmet replacement in case of accident',
                'Full warranty',
                'VIP service & maintenance',
                'Exclusive member events and rides',
                'Member-only discounts from top brands (coming soon)',
            ],
            image: ClassonIcons.Blue,
            cost: 199,
            quantity: 1,
            priceId: PRICE_ID.ClassonBeta,
            disabled: false,
        },
        BV1Reservation: {
            title: 'Reservation Fee ($1) - Available (Expected: Late 2022)',
            body: ['$89/mo. plan', '$99 one-time set-up'],
            image: BV1Gallery[0].thumbnail,
            cost: 1,
            quantity: 1,
            priceId: PRICE_ID.BV1Reservation,
            disabled: false,
        },
        BV1BeyondPremiereFree: {
            title: 'Beyond Premiere ',
            body: [
                '24/7 Support',
                'Local Maintenance',
                //'Free e-bike swap, no questions asked',
                'GPS Tracking',
                'Exclusive member events and rides',
                'Member-only discounts from top brands (coming soon)',
            ],
            image: CreditCard,
            cost: 0,
            quantity: 1,
            priceId: PRICE_ID.BeyondPremiereFree,
            disabled: true,
        },
    },
    Footer: {
        SV1PickUpFooter: [
            '* Monthly subscription of $69/mo. will begin at time of pickup',
            '** Pickup available at Beyond HQ (368 Broadway, New York, NY 10013)',
        ],
        MarchPromoFooter: [
            '* Monthly subscription of $49/mo. will begin at time of pickup',
            '* Free month promotion is for second full paid month',
            '** Pickup available at Beyond HQ (368 Broadway, New York, NY 10013)',
        ],
    },
};
