import React from 'react';

const productTitleStyle = { fontWeight: 'bold' };

const ProductDescription = ({ title, body }) => {
    let titleStyle = productTitleStyle;
    if (title.includes('Pre-Summer Sale')) {
        titleStyle = { ...titleStyle, color: 'red' };
    }

    return (
        <div className="col-5 p-0">
            <div style={titleStyle}>{title}</div>
            {body.map((description, index) => {
                return (
                    <div key={index} className="pleft-5">
                        – {description}
                    </div>
                );
            })}
        </div>
    );
};

export default ProductDescription;
